import React from "react";
import { Link, StaticQuery, graphql } from "gatsby";
import Logo from "../assets/svg/logo.svg";
import styled from "styled-components";
import CIcon from "@coreui/icons-react";
import { cilChevronBottom } from "@coreui/icons";

const HeaderStyles = styled.header`
  background: var(--black);
  position: sticky;
  top: 0;
  z-index: 9;
  padding: 22.5px 30px;
  transition: background 0.275s ease;

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  button {
    cursor: pointer;

    span {
      display: block;
      width: 26px;
      height: 2px;
      margin-bottom: 4px;
      background: var(--white);

      &:nth-child(2) {
        width: 22px;
        transition: width 0.3s ease;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &.active {
    background: var(--black);

    button span {
      &:nth-child(2) {
        width: 26px;
      }
    }
  }

  .parent-menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    width: 100%;
    top: 90px;
    left: 0;
    padding: 0;
    opacity: 0;
    height: 0;
    overflow: hidden;
    background: var(--black);
    transition: opacity 0.3s ease;

    &.active {
      opacity: 1;
      height: calc(100vh - 90px);
    }

    li {
      display: block;
      margin: 0;
      text-align: center;
      position: relative;

      a {
        display: block;
        text-transform: uppercase;
        color: var(--white);
        text-decoration: none;
        font-family: "Roboto Condensed", sans-serif;
        font-size: 2rem;
        font-weight: 700;
        padding: 1rem 0;

        &[aria-current="page"] {
          color: var(--blue);
        }
      }

      &:last-child {
        margin-bottom: 0;
      }

      .child-menu {
        flex-basis: 100%;

        li {
          a {
            font-size: 1.5rem;

            &[aria-current="page"] {
              color: var(--blue);
            }
          }
        }
      }
    }
  }

  &.home {
    background: none;

    &.active,
    &.scroll {
      background: var(--black);
    }
  }

  @media screen and (min-width: 981px) {
    padding: 3.6rem 3rem;

    &.home {
      &:hover {
        background: var(--black);
      }
    }

    .container {
      display: flex;
      justify-content: start;
      align-items: center;
      overflow: visible;
    }

    .logo {
      svg {
        height: 57px;
        width: auto;
      }
    }

    nav {
      margin-left: 10rem;
    }

    .parent-menu {
      opacity: 1;
      height: auto;
      width: auto;
      background: none;
      display: block;
      position: relative;
      top: auto;
      left: auto;
      overflow: visible;

      li {
        display: inline-block;
        margin: 0 1.75rem;
        position: relative;

        a {
          &:after {
            content: "";
            display: block;
            width: 0;
            height: 2px;
            position: absolute;
            bottom: 0;
            left: 0;
            background: var(--white);
            transition: width 0.3s ease;
          }

          &[aria-current="page"] {
            color: var(--white);

            &:after {
              width: 100%;
            }
          }
        }

        &:hover {
          a {
            &:after {
              width: 100%;
            }
          }
        }

        &.has-children:hover a:after {
          width: calc(100% - 26px);
        }

        .gg-chevron-down {
          box-sizing: border-box;
          position: relative;
          display: inline-block;
          width: 22px;
          height: 22px;
          color: var(--white);
          vertical-align: middle;
        }

        .gg-chevron-down::after {
          content: "";
          display: block;
          box-sizing: border-box;
          position: absolute;
          width: 10px;
          height: 10px;
          border-bottom: 2px solid;
          border-right: 2px solid;
          transform: rotate(45deg);
          left: 4px;
          top: 2px;
        }

        .child-menu {
          display: none;
          position: absolute;
          top: 44px;
          left: 0;
          min-width: 190px;
          box-sizing: border-box;
          padding: 44px 25px 0;
          transform: translateX(calc(25% - 103px));
          background: rgba(0, 0, 0, 0.8);

          &.menu-open {
            display: block;
          }

          li {
            margin: 0;
            padding: 0;
            display: block;

            a {
              font-weight: 400;
              padding: 20px 0;
              border-bottom: 1px solid var(--white);

              &:hover,
              &[aria-current="page"] {
                font-style: italic;
                font-weight: 700;
                color: var(--white);
              }

              &:after {
                display: none;
              }
            }

            &:last-child a {
              border: 0;
            }
          }
        }
      }
    }
  }
`;

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      toggled: false,
      scroll: 0,
    };
  }

  toggleMenu = () => {
    this.setState({ toggled: !this.state.toggled });
  };

  showSubMenu = event => {
    const parent = event.currentTarget;
    const child = parent.querySelector(".child-menu");

    if (parent.classList.contains("has-children") && child) {
      child.classList.add("menu-open");
    } else {
      this.hideSubMenu();
    }
  };

  hideSubMenu = () => {
    document.querySelector(".child-menu").classList.remove("menu-open");
  };

  componentDidMount = () => {
    window.addEventListener("scroll", this.handleScroll);
    document
      .querySelectorAll(".parent-menu > li")
      .forEach(item => item.addEventListener("mouseover", this.showSubMenu));
    document.querySelector(".child-menu").addEventListener("mouseleave", this.hideSubMenu);
    document.querySelector("header").addEventListener("mouseleave", this.hideSubMenu);
  };

  componentWillUnmount = () => {
    window.removeEventListener("scroll", this.handleScroll);
    document
      .querySelectorAll(".parent-menu > li")
      .forEach(item => item.removeEventListener("mouseover", this.showSubMenu));
    document.querySelector(".child-menu").removeEventListener("mouseleave", this.hideSubMenu);
    document.querySelector("header").addEventListener("mouseleave", this.hideSubMenu);
  };

  handleScroll = event => {
    const scrollTop =
      window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

    this.setState({
      scroll: scrollTop,
    });
  };

  render() {
    return (
      <HeaderStyles
        className={`${this.props.page} ${this.state.toggled ? "active" : ""} ${
          this.state.scroll > 0 ? "scroll" : ""
        }`}
      >
        <div className="container">
          <Link to="/" className="logo">
            <Logo />
          </Link>
          <nav>
            <button className="mobile" onClick={this.toggleMenu}>
              <span></span>
              <span></span>
              <span></span>
            </button>

            <StaticQuery
              query={graphql`
                query MenuQuery {
                  wpgraphql {
                    menu(id: "Top", idType: NAME) {
                      id
                      menuItems {
                        nodes {
                          id
                          path
                          label
                          childItems {
                            nodes {
                              id
                              label
                              path
                            }
                          }
                          parentId
                        }
                      }
                    }
                  }
                }
              `}
              render={data => (
                <ul
                  className={`parent-menu ${this.state.toggled ? "active" : ""}`}
                  key={data.wpgraphql.menu.id}
                >
                  {data.wpgraphql.menu.menuItems.nodes.length ? (
                    <React.Fragment>
                      {data.wpgraphql.menu.menuItems.nodes.map(menu => (
                        <React.Fragment key={menu.id}>
                          {menu.parentId ? (
                            ""
                          ) : (
                            <li className={menu.childItems.nodes.length ? "has-children" : ""}>
                              <Link to={menu.path}>
                                {menu.label}{" "}
                                {menu.childItems.nodes.length ? (
                                  <span className="gg-chevron-down desktop" />
                                ) : (
                                  ""
                                )}
                              </Link>

                              {menu.childItems.nodes.length ? (
                                <React.Fragment>
                                  <ul className="child-menu">
                                    {menu.childItems.nodes.map(child => (
                                      <li key={child.id}>
                                        <Link to={child.path}>{child.label}</Link>
                                      </li>
                                    ))}
                                  </ul>
                                </React.Fragment>
                              ) : (
                                ""
                              )}
                            </li>
                          )}
                        </React.Fragment>
                      ))}
                    </React.Fragment>
                  ) : (
                    ""
                  )}
                </ul>
              )}
            />
          </nav>
        </div>
      </HeaderStyles>
    );
  }
}

export default Header;
