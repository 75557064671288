import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { SEOContext } from "gatsby-plugin-wpgraphql-seo";
import PropTypes from "prop-types";
import SimpleReactLightbox from "simple-react-lightbox";
import "normalize.css";
import GlobalStyles from "../assets/styles/GlobalStyles";
import Header from "./header";
import Footer from "./footer";

const Layout = props => {
  const {
    wpgraphql: { seo },
  } = useStaticQuery(graphql`
    query SiteInfoQuery {
      wpgraphql {
        seo {
          contentTypes {
            post {
              title
              schemaType
              metaRobotsNoindex
              metaDesc
            }
            page {
              metaDesc
              metaRobotsNoindex
              schemaType
              title
            }
          }
          webmaster {
            googleVerify
            yandexVerify
            msVerify
            baiduVerify
          }
          schema {
            companyName
            personName
            companyOrPerson
            wordpressSiteName
            siteUrl
            siteName
            inLanguage
            logo {
              sourceUrl
              mediaItemUrl
              altText
            }
          }
          social {
            facebook {
              url
              defaultImage {
                sourceUrl
                mediaItemUrl
              }
            }
            instagram {
              url
            }
            linkedIn {
              url
            }
            mySpace {
              url
            }
            pinterest {
              url
              metaTag
            }
            twitter {
              username
            }
            wikipedia {
              url
            }
            youTube {
              url
            }
          }
        }
      }
    }
  `);

  return (
    <SEOContext.Provider value={{ global: seo }}>
      <SimpleReactLightbox>
        <GlobalStyles />
        <Header page={props.page} />
        <div className="main">{props.children}</div>
        <Footer />
      </SimpleReactLightbox>
    </SEOContext.Provider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
