import React from "react";
import { Link, StaticQuery, graphql } from "gatsby";
import styled from "styled-components";

const FooterMenuStyles = styled.nav`
  display: inline-block;

  ul {
    li {
      position: relative;
      margin-left: 5px;
      margin-right: 0;

      &:before {
        content: "";
        display: inline-block;
        height: 12px;
        width: 1px;
        margin-right: 5px;
        margin-bottom: -1px;
        background: var(--dark-grey);
      }

      a {
        text-transform: uppercase;
      }
    }
  }
`;

const FooterMenu = () => (
  <FooterMenuStyles>
    <ul>
      <StaticQuery
        query={graphql`
          query FooterMenuQuery {
            wpgraphql {
              menu(id: "Footer", idType: NAME) {
                menuItems {
                  nodes {
                    id
                    label
                    path
                  }
                }
              }
            }
          }
        `}
        render={data => {
          const menuItems = data.wpgraphql.menu.menuItems.nodes;
          let content;

          if (menuItems.length) {
            content = menuItems.map(menu => {
              return (
                <li key={menu.id}>
                  <Link to={menu.path}>{menu.label}</Link>
                </li>
              );
            });
          }

          return content;
        }}
      />
    </ul>
  </FooterMenuStyles>
);

export default FooterMenu;
