import React from "react";
import { Link, StaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import Logo from "../assets/svg/logo.svg";
import SocialNav from "./social-nav";
import Newsletter from "./newsletter";
import FooterMenu from "./footer-menu";
import styled from "styled-components";

const FooterStyles = styled.footer`
  background: var(--black);
  color: var(--white);

  .footer-top {
    padding: 30px;
    background: var(--dark);
    font-size: 1.6rem;

    .logo {
      display: block;
      text-align: center;
      padding-bottom: 30px;
    }

    .row {
      justify-content: space-between;

      .col {
        .footer-contact {
          p {
            font-size: 1.6rem;
            line-height: 3rem;
            font-weight: 300;
            margin-top: 0.8rem;
            margin-bottom: 1rem;
          }
        }
      }
    }
  }

  .copy {
    background: var(--black);
    color: var(--dark-grey);
    font-size: 1.2rem;
    line-height: 2rem;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    /*border-top: 1px solid var(--medium-grey);*/
    padding: 20px 30px;
    text-align: center;

    a {
      color: var(--dark-grey);
      text-decoration: none;
      transition: color 0.3s ease;

      &:hover {
        color: var(--white);
      }
    }
  }

  @media screen and (min-width: 981px) {
    .footer-top {
      padding: 8rem 3rem;

      .logo {
        padding-bottom: 8rem;

        svg {
          width: 149px;
          height: 112px;
        }
      }

      nav {
        ul.footer-nav {
          line-height: 2rem;
          columns: 2;
          column-gap: 10vw;

          li {
            display: block;
            font-size: 1.6rem;
            font-weight: 700;
            margin-bottom: 1rem;
            margin-right: 0;

            a {
              color: var(--white);
              text-decoration: none;
              transition: color 0.3s ease;
              text-transform: capitalize;

              &:hover {
                color: var(--blue);
              }
            }

            ul {
              margin: 0.9rem 0 2rem;

              li {
                height: 20px;

                a {
                  font-weight: 300;
                }
              }
            }
          }
        }
      }
    }

    .copy {
      padding: 3rem;
      font-size: 1.6rem;
    }
  }
`;

const Footer = () => {
  const today = new Date();
  const year = today.getFullYear();

  return (
    <FooterStyles>
      <div className="footer-top">
        <Link to="/" className="logo">
          <Logo />
        </Link>
        <div className="container">
          <div className="row">
            <StaticQuery
              query={graphql`
                query FooterQuery {
                  wpgraphql {
                    menu(id: "Top", idType: NAME) {
                      menuItems {
                        nodes {
                          id
                          path
                          label
                          childItems {
                            nodes {
                              id
                              label
                              path
                            }
                          }
                          parentId
                        }
                      }
                    }
                    options {
                      acfOptions {
                        contact {
                          address
                          fieldGroupName
                          title
                        }
                        newsletter {
                          content
                          fieldGroupName
                          title
                        }
                        social {
                          title
                          items {
                            network
                            url
                          }
                        }
                      }
                    }
                  }
                }
              `}
              render={data => (
                <>
                  <div className="col">
                    <div className="desktop">
                      <nav>
                        <ul className="footer-nav">
                          {data.wpgraphql.menu.menuItems.nodes.length ? (
                            <React.Fragment>
                              {data.wpgraphql.menu.menuItems.nodes.map(menu => (
                                <React.Fragment key={`footer-${menu.id}`}>
                                  {menu.parentId ? (
                                    ""
                                  ) : (
                                    <li
                                      className={menu.childItems.nodes.length ? "has-children" : ""}
                                    >
                                      <Link to={menu.path}>
                                        {menu.label}{" "}
                                        {menu.childItems.nodes.length ? (
                                          <span className="gg-chevron-down desktop" />
                                        ) : (
                                          ""
                                        )}
                                      </Link>

                                      {menu.childItems.nodes.length ? (
                                        <React.Fragment>
                                          <ul className="child-menu">
                                            {menu.childItems.nodes.map(child => (
                                              <li key={`footer-${child.id}`}>
                                                <Link to={child.path}>{child.label}</Link>
                                              </li>
                                            ))}
                                          </ul>
                                        </React.Fragment>
                                      ) : (
                                        ""
                                      )}
                                    </li>
                                  )}
                                </React.Fragment>
                              ))}
                            </React.Fragment>
                          ) : (
                            ""
                          )}
                        </ul>
                      </nav>
                    </div>
                  </div>
                  <div className="col">
                    <div className="desktop">
                      <div className="footer-contact">
                        <strong>{data.wpgraphql.options.acfOptions.contact.title}</strong>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: data.wpgraphql.options.acfOptions.contact.address,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <Newsletter data={data.wpgraphql.options.acfOptions.newsletter} />
                  </div>
                  <div className="col">
                    <SocialNav data={data.wpgraphql.options.acfOptions.social} />
                  </div>
                </>
              )}
            />
          </div>
        </div>
      </div>

      <div className="copy">
        WK Studios© {year}. Website design by{" "}
        <a href="https://savyagency.com" target="_blank" rel="noreferrer">
          Savy Agency
        </a>
        <FooterMenu />
      </div>
    </FooterStyles>
  );
};

export default Footer;
