import React, { useState } from "react";
import styled from "styled-components";
import CIcon from "@coreui/icons-react";
import { cilArrowRight } from "@coreui/icons";

const NewsLetterStyles = styled.div`
  text-align: center;

  h3 {
    font-weight: 700;
    line-height: 2rem;
    font-size: 1.4rem;
    margin-bottom: 1rem;
    margin-top: 0;
  }

  p {
    font-size: 1.4rem;
    line-height: 2rem;
    font-weight: 300;
    margin-top: 0;
    margin-bottom: 1rem;
  }

  form {
    margin: 0;
    padding: 1rem 0;
    text-align: right;

    input {
      font-family: "Montserrat", sans-serif;
      font-size: 1.4rem;
      display: block;
      width: 100%;
      background: none;
      border: 0;
      border-bottom: 1px solid var(--lighter-grey);
      color: var(--white);
      padding: 1.5rem 0;
      font-weight: 300;

      &::placeholder {
        color: var(--white);
      }

      &:focus::placeholder {
        color: var(--lighter-grey);
      }
    }

    button {
      background: none;
      color: var(--white);
      font-weight: 300;
      font-family: "Montserrat", sans-serif;
      padding: 1.5rem 0;

      .c-icon {
        color: var(--blue);
        margin: 0 0 -2px 3px;
        width: 1.4rem;
        height: 1.4rem;
        font-size: 1.4rem;
        transition: color 0.3s ease;
      }

      &:hover {
        .c-icon {
          color: var(--white);
        }
      }
    }
  }

  @media screen and (min-width: 981px) {
    text-align: left;

    h3 {
      font-size: 1.6rem;
    }

    p {
      font-size: 1.6rem;
    }

    form {
      display: flex;

      button {
        margin-left: 4rem;
        min-width: 80px;
      }
    }
  }
`;

const NewsLetter = props => {
  const [state, setState] = useState({
    message: "",
    status: "",
    email: "",
  });

  const handleChange = event => {
    setState({
      email: event.target.value,
      message: "",
      status: "",
    });
  };

  const handleSubscription = event => {
    event.preventDefault();

    const xhr = new XMLHttpRequest();
    const form = event.target;
    xhr.open(form.method, form.action);
    xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return;
      const { status, message } = JSON.parse(xhr.response);
      if (xhr.status === 200 && status === 201) {
        form.reset();
        setState({ message: "Thank you for subscribing", status: status, email: state.email });
      } else {
        setState({ message: message, status: status, email: state.email });
      }
    };
    xhr.send(JSON.stringify({ email: state.email }));
  };

  return (
    <NewsLetterStyles>
      <h3>{props.data.title}</h3>
      <div
        dangerouslySetInnerHTML={{
          __html: props.data.content,
        }}
      />
      <form
        method="post"
        action={`${process.env.BASE_URL}/wp-json/newsletter/v1/signup`}
        onSubmit={handleSubscription}
      >
        <input type="email" placeholder="Email" required onChange={handleChange} />
        <button>
          Submit <CIcon content={cilArrowRight} size="custom-size" />
        </button>
      </form>
      {state.message ? <p className="response">{state.message}</p> : null}
    </NewsLetterStyles>
  );
};

export default NewsLetter;
