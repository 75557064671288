import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
  :root {
    --black: #000;
    --white: #fff;
    --dark: #1A1A1A;
    --text: #292929;
    --grey: #D2D2D2;
    --blue: #2451AF;
    --light-grey: #F1EFEF;
    --lighter-grey: #979797;
    --medium-grey: #707070;
    --dark-grey: #4A4A4A;
    --p-grey: #5E5E5E;
  }

  html {
    font-size: 10px;
  }

  body {
    background: var(--white);
    color: var(--text);
    font-family: Helvetica, Arial, sans-serif;
    font-size: 1.4rem;
  }

  h1, .h1 {
    font-size: 3rem;
    line-height: 3rem;
    font-weight: 700;
    margin-bottom: 2.2rem;
    margin-top: 0;
  }

  h2, .h2 {
    font-size: 2.4rem;
    line-height: 2.4rem;
    font-weight: 700;
    margin-bottom: 2.2rem;
    margin-top: 0;
  }

  a {
    color: var(--blue);
  }

  img {
    max-width: 100%;
    height: auto;
  }

  button {
    background: none;
    border: 0;
    outline: 0;
    cursor: pointer;
  }

  .button {
    font-family: "Roboto Condensed", sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 1.4rem;
    background: var(--text);
    border-radius: 20px;
    padding: 1.2rem 2.4rem;
    color: var(--white);
    text-decoration: none;
    display: inline-block;
    margin-bottom: 2.2rem;
    transition: all 0.3s ease;

    &:hover {
      background: var(--blue);
    }

    &.button-light {
      background: var(--white);
      color: var(--dark);

      &:hover {
        background: var(--dark);
        color: var(--white);
      }
    }
  }

  nav {
    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;

      li {
        display: inline-block;
        margin-right: 1rem;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .mobile {
    display: block;
  }

  .desktop {
    display: none;
  }

  .content {
    padding: 3rem;

    p {
      margin: 0 0 2.2rem;
      color: var(--p-grey);

      &:last-child {
        margin-bottom: 0;
      }
    }

    .content-wrap {
      p {
        margin: 0 0 2.2rem;
      }
    }
  }

  .sub-title {
    font-size: 1.4rem;
    line-height: 1.7rem;
    font-family: "Roboto Condensed", sans-serif;
    font-weight: 700;
    margin-bottom: 1rem;
  }

  .swiper-container {
    overflow: hidden;

    .swiper-wrapper {
      display: flex;

      .swiper-slide {
        flex-shrink: 0;
      }
    }
  }

  @media (min-width: 981px) {
    .mobile {
      display: none;
    }

    .desktop {
      display: block;
    }

    .container {
      width: 100%;
      max-width: 1645px;
      margin: 0 auto;
      overflow: hidden;
    }

    .sub-title {
      font-size: 1.6rem;
      margin-bottom: 3rem;
    }

    h1, .h1 {
      font-size: 6rem;
      line-height: 6rem;
    }

    h2, .h2 {
      font-size: 6rem;
      line-height: 6rem;
    }

    p {
      font-size: 1.6rem;
      line-height: 1.9rem;
      margin-bottom: 2.5rem;
    }

    .row {
      display: flex;

      .col {
        flex: 0 1 auto;
      }
    }

    .content {
      padding: 10rem 3rem;
    }
  }
`;

export default GlobalStyles;
