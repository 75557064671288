import React from "react";
import styled from "styled-components";
import CIcon from "@coreui/icons-react";
import {
  cibFacebookF,
  cibTwitter,
  cibLinkedinIn,
  cibInstagram,
  cibYoutube,
  cibPinterest,
} from "@coreui/icons";

const socialNetworks = {
  cibFacebookF: cibFacebookF,
  cibTwitter: cibTwitter,
  cibLinkedinIn: cibLinkedinIn,
  cibInstagram: cibInstagram,
  cibYoutube: cibYoutube,
  cibPinterest: cibPinterest,
};

const SocialNavStyles = styled.div`
  padding: 10px 0 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  a {
    display: block;
    border-radius: 100%;
    background: var(--white);
    width: 26px;
    height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--dark);
    transition: background 0.3s ease;

    .c-icon {
      width: 1.4rem;
      height: 1.4rem;
      font-size: 1.4rem;
      transition: color 0.3s ease;
    }

    &:hover {
      background: var(--blue);

      .c-icon {
        color: var(--white);
      }
    }
  }

  @media screen and (min-width: 981px) {
    display: block;
    padding: 0;

    nav {
      margin-top: 1.4rem;
    }
  }
`;

const SocialNav = props => {
  return (
    <SocialNavStyles>
      <strong>{props.data.title}</strong>
      <nav>
        <ul>
          {props.data.items.map((item, index) => {
            return (
              <li key={index}>
                <a href={item.url} target="_blank" rel="noreferrer">
                  <CIcon content={socialNetworks[item.network]} size="custom-size" />
                </a>
              </li>
            );
          })}
        </ul>
      </nav>
    </SocialNavStyles>
  );
};

export default SocialNav;
